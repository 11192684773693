<template>
    <div id="invalid-client">
        <div style="text-align: center;color: #7F7F7F;font-size: 16px;margin:12px 0 21px">无效客户</div>
        <search style="margin-top:10px" :label-arr="labelArr" @clickData="clickData" />

        <el-card class="main" :style="{'margin-top': '10px' }">
            <!-- 搜索区域 -->
            <div class="search flex-sb">
                <div class="RandomCallInTurn">
                    <el-button
                        :disabled="ids.length === 0"
                        type="primary"
                        @click="batchRecovery"
                    >批量恢复</el-button>
                </div>
                <div class="top-search">
                    <el-input
                        v-model="status.keyword"
                        clearable
                        size="small"
                        placeholder="请输入姓名/手机号"
                        @input="findData"
                    />
                    <!-- <i class="el-icon-search"></i> -->
                    <div class="icon-area" @click="findData">
                        <img width="20" height="20" src="@/assets/common/search.png" alt />
                    </div>
                </div>
                <div class="flex-sb right-time">
                    <div>最后跟进时间</div>
                    <div>
                        <el-radio-group v-model="timeTabs" size="small" @change="changeTime">
                            <el-radio-button label="0">全部</el-radio-button>
                            <el-radio-button label="1">今日</el-radio-button>
                            <el-radio-button label="2">昨日</el-radio-button>
                            <el-radio-button label="3">最近一周</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div>
                        <el-date-picker
                            v-model="valuetime"
                            size="small"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="timestamp"
                            :default-time="['00:00:00', '23:59:59']"
                            @change="time"
                        />
                    </div>
                </div>
            </div>

            <el-table
                ref="multipleTable"
                v-loading="false"
                class="table"
                style="margin:10px 0"
                :data="tableDatas"
                border
                :height="tableHeight(332)"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" fixed />
                <el-table-column label="序号" type="index" width="50" fixed />
                <el-table-column label="客户姓名" width="100" prop="name" />
                <el-table-column label="ID" width="100" prop="id" />
                <el-table-column label="员工" width="100" prop="originalStaff" />
                <el-table-column label="客户资质" width="190">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.hasHouse == 1"
                            class="icon house"
                            style="margin-right: 10px"
                        >房</span>
                        <span
                            v-if="scope.row.hasVehicle == 1"
                            class="icon car"
                            style="margin-right: 10px"
                        >车</span>
                        <span
                            v-if="scope.row.hasShebao == 1"
                            style="margin-right: 10px"
                            class="icon shebao"
                        >社</span>
                        <span
                            v-if="scope.row.hasGongjijin == 1"
                            class="icon gongjijin"
                            style="margin-right: 10px"
                        >金</span>
                        <span
                            v-if="scope.row.hasBaodan == 1"
                            style="margin-right: 10px"
                            class="icon baodan"
                        >保</span>
                    </template>
                </el-table-column>
                <el-table-column label="资金需求" min-width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.fundNeeds }}万</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastFollowedTime" label="最后跟进时间" width="160">
                    <template slot-scope="scope">{{ scope.row.lastFollowedTime | getYMDHMS }}</template>
                </el-table-column>
                <el-table-column width="100" prop="followStage" label="跟进阶段" />
                <el-table-column width="100" prop="purposeLevel" label="意向度" />
                <el-table-column prop="residence" label="居住地" />
                <el-table-column prop="qualityLevel" label="资质星级" width="150">
                    <template slot-scope="scope">
                        <el-rate v-model="scope.row.qualityLevel" disabled text-color="#ff9900" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="168" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            slot="reference"
                            size="mini"
                            type="primary"
                            @click="recover(scope.row)"
                        >恢复</el-button>
                        <el-button
                            slot="reference"
                            size="mini"
                            type="primary"
                            @click="seeSetails(scope.row)"
                        >查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="block" style="text-align: right">
                <el-pagination
                    :current-page="page"
                    :page-size="pageLimit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="len"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </el-card>
        <restore-dialog
            :is-show.sync="restoreDialog"
            :optional="optional"
            title="恢复客户"
            @confirm="confirm"
            @close="close"
        />
        <client-info-dialog ref="showDialog" @recover="recovers" @close="close" />
    </div>
</template>

<script>
import search from '@/components/templates/search-open';
import RestoreDialog from '@/components/RestoreDialog';
import ClientInfoDialog from '@/components/ClientInfoDialog';
import resize from '@/mixins/resize';
import {
    getInvalidClientList,
    getInvalidClientInfo,
    recoveryClients,
} from '@/api/invalid-client';
export default {
    mixins: [resize],
    data() {
        return {
            labelArr: [
                '客户资质',
                '资金需求',
                '跟进阶段',
                '意向度',
                '资质星级',
                '标签',
            ],
            status: {
                keyword: '',
                keyword: '', //input搜索关键词
                qualityType: '', //资质类型
                startTime: '', //开始时间
                endTime: '', //结束时间
                fundNeedsScopeType: '', //资金需求
                pageNo: 1, //页码数
                pageSize: 10, //数据量
                tag: '', // 标签
                followStage: '', // 客户跟进阶段（字典），未选传空值
                qualityLevel: '', // 星级
                purposeLevel: '', // 意向等级
            },
            // 流入公共池时间
            timeTabs: 0,
            //时间段搜索
            valuetime: '',
            // 表格数据
            tableData: [
                {
                    lastFollowedTime: 1,
                    followLogList: [
                        {
                            followedTime: 'ASD',
                            follower: 'ASDA',
                            modifyContent: 'ASD',
                            followContent: 'ASD',
                        },
                    ],
                    fundNeeds: 1,
                    name: '啊实打实',
                    hasHouse: 1,
                    hasVehicle: 1,
                    residence: 'ASDAS',
                    hasShebao: 1,
                    sex: 1,
                    hasGongjijin: 1,
                    age: 1,
                    hasBaodan: 1,
                    qualityLevel: 1,
                    id: 1,
                    purposeLevel: 1,
                    createdTime: 1,
                    followStage: 1,
                    category: 1,
                    transferRemainingTime: 1,
                    department: 'SADA',
                    originalStaff: 'ASDAS',
                    levelArr: [],
                    stageArr: [],
                },
            ],
            //  开启表格加载等待
            loading: false,
            // 多选列表
            ids: [],
            // 当前页
            page: 1,
            // 一次获取几条
            pageLimit: 10,
            // 总条数
            len: 0,
            // 恢复对话框是否显示
            restoreDialog: false,
            optional: ['导入位置'],
        };
    },
    methods: {
        // 顶部筛选
        clickData(data) {
            this.status.pageNo = 1;
            this.status.tag = data.tag;
            this.status.qualityType = data.qualityType;
            this.status.fundNeedsScopeType = data.fundNeedsScopeType;
            this.status.followStage = data.followStage;
            this.status.qualityLevel = data.qualityLevel;
            this.status.purposeLevel = data.purposeLevel;
        },
        // 搜索触发事件
        findData() {
            this.status.pageNo = 1;
            this.status.keyword = val;
        },
        // 选择时间筛选
        changeTime() {
            console.log(this.timeTabs);
            const start = new Date(new Date(new Date().toLocaleDateString()));
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            if (this.timeTabs == 0) this.valuetime = [0, end]; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
            this.status.pageNo = 1;
        },
        // 时间选择
        time(val) {
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
            } else {
                this.timeTabs = '';
                this.valuetime = val;
            }
            this.status.pageNo = 1;
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
        },
        //分页选择
        handleSizeChange(val) {
            this.size = val;
            this.status.pageSize = val;
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
        },
        // 批量恢复
        batchRecovery() {
            this.restoreDialog = true;
        },
        // 恢复
        recovers() {
            this.restoreDialog = true;
        },
        // 恢复
        recover(data) {
            this.ids = [data.id];
            this.$refs.multipleTable.clearSelection();
            this.restoreDialog = true;
        },
        // 确定恢复
        confirm(data) {
            this.recoveryClients({
                ...data,
                ids: this.ids,
            });
            this.ids = [];
        },
        async recoveryClients(val) {
            const { data, code, message } = await recoveryClients(val);
            if (code !== '0') return console.log(message);
            this.getInvalidClientList(this.status);
        },
        // 查看详情
        seeSetails(data) {
            this.ids = [data.id];
            this.$refs.multipleTable.clearSelection();
            this.$refs.showDialog.init(data.id, true);
        },
        handleSelectionChange(val) {
            this.ids = [];
            val.forEach((item) => {
                this.ids.push(item.id);
            });
        },
        // 获取无效列表
        async getInvalidClientList(vals) {
            const { data, code } = await getInvalidClientList(vals);
            if (code !== '0') return console.log('获取无效列表失败');
            this.tableData = data.list;
            this.len = data.total;
        },
        close() {
            this.ids = [];
            this.$refs.multipleTable.clearSelection();
        },
    },
    computed: {
        tableDatas() {
            let arr = [];
            try {
                this.tableData.forEach((item) => {
                    const followStage = this.stageArr.find((data) => {
                        return data.number === item.followStage;
                    });
                    const purposeLevel = this.levelArr.find((data) => {
                        return data.number === item.purposeLevel;
                    });
                    item.followStage = followStage.cnVal;
                    item.purposeLevel = purposeLevel.cnVal;
                    arr.push(item);
                });
            } catch (error) {}
            return arr;
        },
    },
    watch: {
        status: {
            immediate: true,
            deep: true,
            handler(newData) {
                this.levelArr = this.common.allValueNeed('loan-purpose-level');
                this.stageArr = this.common.allValueNeed('client-follow-stage');
                this.getInvalidClientList(newData);
            },
        },
    },
    components: {
        search,
        RestoreDialog,
        ClientInfoDialog,
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';
#invalid-client {
    padding: 10px 20px 0 20px;
}

.flex {
    display: flex;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #333;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search {
    .RandomCallInTurn {
        position: absolute;
    }
    .top-search {
        position: relative;
        transform: translateX(100px);
        /deep/ .el-input__clear {
            margin-right: 23px;
        }

        .icon-area {
            position: absolute;
            right: 5px;
            top: 8px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }

    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }

        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }

        /deep/ .el-radio-button__inner {
            color: #aaa;
        }
    }
}

.table-area {
    margin-top: 10px;
}

.box {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .main {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.new {
    background-color: #ff8040;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 2%;
}

/deep/ .el-input__clear {
    margin-right: 23px;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}

.el-popover {
    padding: 20px !important;
}
</style>